import * as React from "react";
import { Link } from "gatsby";
import { Page, Layout, DetailPageSlider } from "../../components";
import { withStyles, Container, Grid, Box, Typography, Button, CircularProgress, Breadcrumbs, Select, InputLabel, FormControl, TextField, MenuItem, IconButton } from '@material-ui/core/';
import Rating from '@material-ui/lab/Rating';
import ProductAttributes from '../../components/ProductAttributes';
import { useAllProductsData, useCartData } from '../../helpers';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { AiOutlineHeart } from 'react-icons/ai';
import { BiRupee } from 'react-icons/bi';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

const ProductDetails = (props) => {
  const [selectedProductSku, setSelectedProductSku] = React.useState('');
  const [selectedSku, setSelectedSku] = React.useState('');
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  console.log('location', props.id);
  const productId = parseInt(props.id);
  const allProducts = useAllProductsData();
  const cartData = useCartData();
  // const loading = allProducts?.productDetailsLoading;
  const productData = allProducts?.productDetails || [];

  console.log('productData', productData)

  const fetchProductDetails = async () => {
    setLoading(true);
    const res = await allProducts?.getProductDetails({
      ProductId: productId
    });
    setLoading(false);
  }

  React.useEffect(() => {
    if (productId) {
      fetchProductDetails();
    }
  }, [productId])

  React.useEffect(() => {
    // setSelectedSku({ ...selectedProductSku, maxQnt: selectedProductSku.Quantity, Quantity: selectedProductSku.Quantity > 10 ? 1 : (selectedProductSku.Quantity || 1) });
    setSelectedSku({ ...selectedProductSku, maxQnt: selectedProductSku.Quantity, Quantity: 1 });
  }, [selectedProductSku])

  console.log('selectedProductSku', selectedProductSku)
  console.log('selectedSku', selectedSku)

  return (
    <Layout>
      <Page>
        <Container>
          {/* {props.id} */}
          {loading ? <>Loading...</> : <>
            <Box paddingTop={7} paddingBottom={5}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Home
                </Link>
                {/* <Link color="inherit" to="/getting-started/installation/">
                Core
              </Link> */}
                <Typography color="textPrimary">{productData[0]?.ProductName}</Typography>
              </Breadcrumbs>
            </Box>
            <Box >
              {productData.length > 0 ? <Grid container spacing={2}>
                <Grid item md={7} lg={7} xs={12} sm={12}>
                  <DetailPageSlider images={selectedProductSku?.Images || []} />
                </Grid>
                <Grid item md={5} lg={5} xs={12} sm={12} style={{ display: 'flex' }}>
                  <Box width='24px' />
                  <Box width="100%">
                    <Typography variant='h4'>{productData[0]?.ProductName}</Typography>
                    <Box height='16px' />
                    <Box className={props.classes.priceSection}>
                      <Box display='flex' alignItems='center'>
                        <Box style={{ textDecoration: 'line-through' }}><Typography variant='h6' style={{ display: 'flex', alignItems: 'center' }}><BiRupee /> 500.00 </Typography></Box>
                        <Box width='16px' />
                        <Box style={{ color: '#ec0101' }}><Typography variant='h6' style={{ display: 'flex', alignItems: 'center' }}><BiRupee /> {selectedProductSku?.Price}</Typography></Box>
                      </Box>
                      <Box>
                        <Rating name="read-only" value={4} readOnly />
                      </Box>
                    </Box>
                    <Box height='24px' />
                    <Typography variant='body2'>
                      {productData[0]?.ProductDesc}
                    </Typography>
                    <Box>
                      <ProductAttributes productData={productData} setSelectedProductSku={setSelectedProductSku} />
                    </Box>

                    <Box marginTop={3} className={props.classes.buttons}>
                      {console.log('selectedProductSku', selectedProductSku)}
                      {/* <FormControl variant="outlined"> */}
                      {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                      {/* <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedSku?.Quantity}
                        variant="outlined"
                        onChange={(e) => setSelectedSku({ ...selectedSku, Quantity: e.target.value })}
                      // label="Quantity"
                      >
                        {[...Array(selectedProductSku?.Quantity > 10 ? 10 : selectedProductSku?.Quantity).keys()].map((l) => (
                          <MenuItem value={l + 1}>{l + 1}</MenuItem>
                        ))}

                      </Select> */}
                      {/* </FormControl> */}
                      <Box display="flex" width="140px">
                        <IconButton disabled={selectedSku?.Quantity === selectedProductSku?.Quantity} size='small' style={{ color: '#000' }} onClick={(e) => setSelectedSku({ ...selectedSku, Quantity: parseInt(selectedSku?.Quantity) + 1 })}>
                          <AiFillPlusCircle style={{ fontSize: '24px' }} />
                        </IconButton>
                        <TextField InputProps={{
                          inputProps: {
                            max: selectedSku?.Quantity, min: 1
                          }
                        }} size="small" variant="outlined" type="number" value={selectedSku?.Quantity} onChange={(e) => setSelectedSku({ ...selectedSku, Quantity: e.target.value })} />
                        <IconButton disabled={selectedSku?.Quantity === 1} size='small' style={{ color: '#000' }} onClick={(e) => setSelectedSku({ ...selectedSku, Quantity: parseInt(selectedSku?.Quantity) - 1 })}>
                          <AiFillMinusCircle style={{ fontSize: '24px' }} />
                        </IconButton>
                      </Box>
                      <Box width='16px' />
                      <Button
                        disabled={selectedProductSku?.StockStatus === false || selectedSku?.Quantity > selectedProductSku?.Quantity}
                        variant='contained'
                        color='secondary'
                        onClick={() => cartData?.addItemTocart(selectedSku)}
                        startIcon={<HiOutlineShoppingBag />}
                      >
                        {selectedProductSku?.StockStatus === false ? 'Out Of Stock!' : 'Add to Cart'}
                      </Button>
                      <Box width='16px' />
                      {/* <Button variant='contained' style={{ borderRadius: '25px' }} startIcon={<AiOutlineHeart />}>Add to wishlist</Button> */}
                      <IconButton style={{ background: '#eee', width: '50px' }} variant='contained' ><AiOutlineHeart /></IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
                : <Box>{loading ? <>Loading...</> : <>Product Unavailable</>}</Box>}
            </Box></>}

        </Container>
      </Page>
    </Layout>
  )
}

const styles = (theme) => ({
  priceSection: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
  },
  buttons: {
    '& .MuiOutlinedInput-root': {
      height: '45px'
    },
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-contained': {
      width: '50%'
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      '& button': {
        width: '100%',
        marginBottom: '16px'
      }
    }
  }
})
export default withStyles(styles, { withTheme: true })(ProductDetails)
